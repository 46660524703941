import React, { useState } from "react"
import styled from "styled-components"
import {
  standardWrapper,
  colors,
  B1White,
  Btn1White,
} from "../../../styles/helpers"

import BackgroundOne from "../../Icons/BackgroundOne"

import submitToServer from "../../FormParts/functions/submitToServer"
import FormSuccess from "../../FormParts/formModals/FormSuccess"
import FormSubmit from "../../FormParts/formModals/FormSubmit"
import FormErrors from "../../FormParts/formModals/FormErrors"

const DoctorReferralForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    patientName: "",
    patientEmail: "",
    patientPhone: "",
    reason: "",
  })

  const [formStatus, setFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
  })

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleOnSubmit = async event => {
    event.preventDefault()
    setFormStatus({
      ...formStatus,
      submitting: true,
    })
    const formDataArray = Object.entries(formData)
    const bodyFormData = new FormData()
    formDataArray.forEach(field => {
      bodyFormData.append(field[0], field[1])
    })

    const response = await submitToServer(778, bodyFormData)

    if (!response.errors) {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: false,
        success: true,
        errors: [],
      })
    } else {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: true,
        success: false,
        errors: response.errorMessages,
      })
    }
  }

  const handleErrorModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
    })
  }

  const handleSuccessModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    })

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      patientName: "",
      patientEmail: "",
      patientPhone: "",
      reason: "",
    })
  }

  return (
    <StyledDiv>
      <div className="form-inner">
        <div className="wrapper">
          <form onSubmit={handleOnSubmit}>
            <InputField>
              <label htmlFor="firstName">
                Your First Name <span className="required">&#42;</span>
                <span
                  className={`error-message ${
                    formStatus.errors.findIndex(
                      error => error.idref === "firstName"
                    ) !== -1 && " error-active"
                  }`}
                >
                  You must input a first name.
                </span>
                <input
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  id="firstName"
                  onChange={handleOnChange}
                  aria-required="true"
                  required
                />
              </label>
            </InputField>

            <InputField>
              <label htmlFor="lastName">
                Your Last Name <span className="required">&#42;</span>
                <span
                  className={`error-message ${
                    formStatus.errors.findIndex(
                      error => error.idref === "lastName"
                    ) !== -1 && " error-active"
                  }`}
                >
                  You must input a last name.
                </span>
                <input
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  id="lastName"
                  onChange={handleOnChange}
                  aria-required="true"
                  required
                />
              </label>
            </InputField>

            <InputField>
              <label htmlFor="email">
                Your Email <span className="required">&#42;</span>
                <span
                  className={`error-message ${
                    formStatus.errors.findIndex(
                      error => error.idref === "email"
                    ) !== -1 && " error-active"
                  }`}
                >
                  You must input a email.
                </span>
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  id="email"
                  onChange={handleOnChange}
                  aria-required="true"
                  required
                />
              </label>
            </InputField>

            <InputField>
              <label htmlFor="phone">
                Your phone <span className="required">&#42;</span>
                <span
                  className={`error-message ${
                    formStatus.errors.findIndex(
                      error => error.idref === "phone"
                    ) !== -1 && " error-active"
                  }`}
                >
                  You must input a phone number.
                </span>
                <input
                  name="phone"
                  type="text"
                  value={formData.phone}
                  id="phone"
                  onChange={handleOnChange}
                  aria-required="true"
                  required
                />
              </label>
            </InputField>

            <InputField>
              <label htmlFor="patientName">
                Patient name<span className="required">&#42;</span>
                <span
                  className={`error-message ${
                    formStatus.errors.findIndex(
                      error => error.idref === "patientName"
                    ) !== -1 && " error-active"
                  }`}
                >
                  You must input a first name.
                </span>
                <input
                  name="patientName"
                  type="text"
                  value={formData.patientName}
                  id="patientName"
                  onChange={handleOnChange}
                  aria-required="true"
                  required
                />
              </label>
            </InputField>

            <InputField>
              <label htmlFor="patientEmail">
                Patient email <span className="required">&#42;</span>
                <span
                  className={`error-message ${
                    formStatus.errors.findIndex(
                      error => error.idref === "patientEmail"
                    ) !== -1 && " error-active"
                  }`}
                >
                  You must input a patient email.
                </span>
                <input
                  name="patientEmail"
                  type="email"
                  value={formData.patientEmail}
                  id="patientEmail"
                  onChange={handleOnChange}
                  aria-required="true"
                  required
                />
              </label>
            </InputField>

            <InputField>
              <label htmlFor="reason">
                Reason for referral <span className="required">&#42;</span>
                <span
                  className={`error-message${
                    formStatus.errors.findIndex(
                      error => error.idref === "reason"
                    ) !== -1
                      ? " error-active"
                      : ""
                  }`}
                >
                  You must provide a reason
                </span>
                <textarea
                  name="reason"
                  value={formData.reason}
                  id="reason"
                  onChange={handleOnChange}
                  rows={6}
                  aria-required="true"
                  required
                />
              </label>
            </InputField>

            <InputField>
              <label htmlFor="patientPhone">
                Patient phone number
                <span className="required">&#42;</span>
                <span
                  className={`error-message ${
                    formStatus.errors.findIndex(
                      error => error.idref === "patientPhone"
                    ) !== -1 && " error-active"
                  }`}
                >
                  You must input a Patient phone number.
                </span>
                <input
                  name="patientPhone"
                  type="text"
                  value={formData.patientPhone}
                  id="patientPhone"
                  onChange={handleOnChange}
                  aria-required="true"
                  required
                />
              </label>
            </InputField>
            <div className="btn-submit">
              <button type="submit">Submit</button>
            </div>
            <div className="required-info">
              <p>&#42; required fields</p>
            </div>
          </form>
        </div>
      </div>
      <div className="bg-one">
        <BackgroundOne />
      </div>
      <FormSubmit isActive={formStatus.submitting} />
      <FormSuccess
        isActive={formStatus.success}
        handleClose={handleSuccessModalClose}
      />
      <FormErrors
        isActive={formStatus.errorWarnDisplay}
        handleClose={handleErrorModalClose}
      />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: relative;
  padding: 0 0 10rem;
  overflow: hidden;

  .form-inner {
    padding: 5rem 0;
    background-color: ${colors.colorPrimary};
  }

  .wrapper {
    ${standardWrapper};
  }

  form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .required-info {
      position: absolute;
      right: 2rem;
      bottom: 0;

      p {
        ${B1White};
        margin: 0;
      }
    }

    .btn-submit {
      margin-top: 2.5rem;
      margin-left: 2rem;

      button {
        ${Btn1White};
      }
    }
  }

  .bg-one {
    position: absolute;
    bottom: -5rem;
    left: 0rem;
    max-width: 40rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -7.5rem;
      left: 10rem;
    }

    @media (min-width: 1025px) {
      bottom: -10rem;
      left: 10rem;
    }

    @media (min-width: 1200px) {
      bottom: -12.5rem;
      left: 10rem;
    }
  }
`

const InputField = styled.div`
  width: calc(100% - 4rem);
  margin: 1rem 2rem;
  padding: 1rem 0;

  @media (min-width: 768px) {
    width: ${props =>
      props.size === "full" ? "calc(100% - 4rem)" : "calc(50% - 4rem)"};
    margin: 1rem 2rem;
  }

  label {
    ${B1White};
    display: block;
    width: 100%;
    line-height: 1.5;

    .error-message {
      display: none;
    }

    .error-active {
      display: inline-block;
      color: red;
      padding-left: 2rem;
    }

    input,
    textarea {
      display: block;
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      padding: 0.9rem 1rem;
      border-radius: 0.2rem;
      color: #000;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      background-color: #fff;
      border: 0.1rem #00296a solid;
      border-radius: 1rem;
    }
  }
`

export default DoctorReferralForm
