import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/templates/common/Hero"
import SimpleContentButton from "../components/templates/common/SimpleContentButton"
import DoctorReferralForm from "../components/templates/doctorReferral/DoctorReferralForm"

const DoctorReferral = props => {
  const { hero, simpleContentButton, seoInfo } = props.data
  const heroData = hero.template.templatePageDoctorReferral
  const simpleContentButtonData =
    simpleContentButton.template.templatePageDoctorReferral
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={heroData} />
      <SimpleContentButton data={simpleContentButtonData} />
      <DoctorReferralForm />
    </Layout>
  )
}

export const doctorReferralTempQuery = graphql`
  query doctorReferralTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_DoctorReferral {
          templatePageDoctorReferral {
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    simpleContentButton: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_DoctorReferral {
          templatePageDoctorReferral {
            simpleContentBtnTitle
            simpleContentBtnParagraph
            simpleContentWithButtonRequired
            simpleContentBtnText
            simpleContentBtnSlug
          }
        }
      }
    }
  }
`

export default DoctorReferral
